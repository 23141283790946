import { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faYoutubeSquare, faSpotify } from '@fortawesome/free-brands-svg-icons'

import NavLink from './NavLink';

class Footer extends Component
{
  render()
  {
    return (
      <div
        className="flex flex-col lg:flex-row w-full overflow-x-hidden bg-black text-white"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + 'img/content/home/footer.jpg'})`,
          backgroundSize: 'auto',
          // backgroundAttachment: 'fixed'
          backgroundPosition: '10% 50%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="flex-1 p-8 text-center lg:text-left">
          <h2 className="text-2xl font-bold">Press</h2>
          <p>
            <a
              href="https://www.fox.com/crime-scene-kitchen/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-pink-600 underline"
            >
              Crime Scene Kitchen
            </a>
          </p>
          <p>
            <a
              href="http://www.dmagazine.com/food-drink/2020/12/how-the-dallas-jewish-community-celebrates-hanukkah-in-the-time-of-covid-19/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-pink-600 underline"
            >
              D Magazine
            </a>
          </p>
          <p>
            <a
              href="http://voyagedallas.com/interview/community-highlights-meet-thuy-linh-carroll-busters-bakeshop/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-pink-600 underline"
            >
              Voyage Dallas Magazine
            </a>
          </p>
        </div>
        <div className="flex-1 flex p-8 items-center justify-center">
          <NavLink to="#" activeColor="text-blue-500">
            <img src="/img/logo.png" alt="Buster's Bakeshop" className="object-contain w-48 lg:w-64 h-full p-4" />
          </NavLink>
        </div>
        <div className="flex-1 flex flex-row p-4 items-center justify-center lg:justify-end">
          <a
              href="https://www.instagram.com/bustersbakeshop"
              target="_blank"
              rel="noreferrer"
              className="hover:text-yellow-600 px-3 py-2 underline"
            >
            <FontAwesomeIcon icon={faInstagram} size="3x" />
          </a>
          <a
            href="https://www.facebook.com/bustersbakeshop"
            target="_blank"
            rel="noreferrer"
            className="hover:text-blue-600 px-3 py-2 underline"
          >
            <FontAwesomeIcon icon={faFacebook} size="3x" />
          </a>
          {/* <a
            href="https://www.behance.net/bustersbakeshop"
            target="_blank"
            rel="noreferrer"
            className="hover:text-blue-400 px-3 py-2 underline"
          >
            <FontAwesomeIcon icon={faBehanceSquare} size="3x" />
          </a> */}
          <a
            href="https://www.youtube.com/channel/UCzOC480ZP-lcAFTDi2aFPVw"
            target="_blank"
            rel="noreferrer"
            className="hover:text-red-500 px-3 py-2 underline"
          >
            <FontAwesomeIcon icon={faYoutubeSquare} size="3x" />
          </a>
          <a
            href="https://open.spotify.com/show/0aQY6r6F4ZTsLYemOFKm2J"
            target="_blank"
            rel="noreferrer"
            className="hover:text-green-400 px-3 py-2 underline"
          >
            <FontAwesomeIcon icon={faSpotify} size="3x" />
          </a>
        </div>
      </div>
    )
  }
}

export default Footer;