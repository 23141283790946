import { Component } from 'react';
import History from './History';

class NavLink extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      active: this.props.to === History.location.hash,
      isHash: this.props.to.substring(0,1) === '#',
      historyUnlisten: null,
    };
  }

  componentDidMount(e)
  {
    let unlisten = History.listen(({ action, location }) => {
      this.setState({ active: this.props.to === location.hash });
    });
    this.setState({ historyUnlisten: unlisten });
  }

  componentWillUnmount()
  {
    this.state.historyUnlisten();
  }

  smoothScroll(e)
  {
    e.preventDefault();
    var section = this.props.to.substring(1);
    let element;
    if (section === null || section === '') {
      element = document.getElementById('top');
    } else {
      element = document.getElementById(section);
    }
    if (element === undefined)
      return;
    element.scrollIntoView({behavior: "smooth"});
    History.push(this.props.to);
  }

  render()
  {
    const classes = [
      (this.state.active ? this.props.activeColor : 'text-white'),
      'hover:'+this.props.activeColor,
      'px-3',
      'py-2',
      this.props.className
    ];

    return (
      <a
        href={this.props.to}
        onClick={this.smoothScroll.bind(this)}
        className={classes.join(' ').trim()}
      >
        {this.props.children}
      </a>
    )
  }
}

export default NavLink;