import { Component } from 'react';

import Spinner from '../../Components/Spinner'

class Order extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      preference: 'call',
      pickup: '',
      occasion: 'Birthday',
      message: '',
      isSubmitting: false,
      hasSubmitted: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event)
  {
    var target = event.target;
    var name = target.name;
    var value = target.type === 'checkbox'? target.checked : target.value;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event)
  {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    fetch(process.env.REACT_APP_URL_ORDER, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY,
      }
    })
    .then(response => response.json())
    .then(data => this.setState({ isSubmitting: false, hasSubmitted: true }));
  }

  render()
  {
    return this.state.hasSubmitted ?
    (
      <div className="text-lg text-purple-200 italic">
        <p>Thank you for reaching out! We will contact you within 24 hours.</p>
      </div>
    )
    : (
      <div
        className="flex-1 flex"
        style={{
        }}
      >
        <form
          onSubmit={this.handleSubmit.bind(this)}
        >
            <div className="mb-4">
              <label className="text-white text-shadow-sm">Your Name</label>
              <div>
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  placeholder="Buster"
                  required
                  className="mt-1 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="text-white text-shadow-sm">Your Phone</label>
              <div>
              <input
                type="tel"
                name="phone"
                autoComplete="phone"
                placeholder="555-555-5555"
                required
                className="mt-1 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                onChange={this.handleInputChange}
              />
              </div>
            </div>

            <div className="mb-4">
              <label className="text-white text-shadow-sm">Your Email</label>
              <div>
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="buster@bustersbake.shop"
                  required
                  className="mt-1 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <fieldset className="mb-4">
              <div>
                <legend className="">Preferred Contact Method</legend>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="pref_call"
                  name="preference"
                  type="radio"
                  defaultChecked
                  value="call"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="pref_call" className="ml-3 block text-sm font-medium text-gray-300">
                  Call
                </label>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="pref_text"
                  name="preference"
                  type="radio"
                  value="text"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="pref_text" className="ml-3 block text-sm font-medium text-gray-300">
                  Text
                </label>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="pref_email"
                  name="preference"
                  type="radio"
                  value="email"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="pref_email" className="ml-3 block text-sm font-medium text-gray-300">
                  Email
                </label>
              </div>
            </fieldset>

            <div className="mb-4">
              <label className="text-white text-shadow-sm">Preferred Pick-up Date</label>
              <div>
                <input
                  type="text"
                  name="pickup"
                  autoComplete="pickup"
                  data-parse="date"
                  placeholder="MM/DD/YYYY"
                  pattern="\d{2}\/\d{2}/\d{4}"
                  required
                  className="mt-1 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <fieldset className="mb-4">
              <div>
                <legend className="">Occasion</legend>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="occasion_birthday"
                  name="occasion"
                  type="radio"
                  defaultChecked
                  value="Birthday"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="occasion_birthday" className="ml-3 block text-sm font-medium text-gray-300">
                  Birthday
                </label>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="occasion_wedding"
                  name="occasion"
                  type="radio"
                  value="Wedding"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="occasion_wedding" className="ml-3 block text-sm font-medium text-gray-300">
                  Wedding
                </label>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="occasion_just_because"
                  name="occasion"
                  type="radio"
                  value="Just Because"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="occasion_just_because" className="ml-3 block text-sm font-medium text-gray-300">
                  Just Because
                </label>
              </div>
              <div className="flex items-center pl-4">
                <input
                  id="occasion_other"
                  name="occasion"
                  type="radio"
                  value="Other"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="occasion_other" className="ml-3 block text-sm font-medium text-gray-300">
                  Other
                </label>
              </div>
            </fieldset>

            <div className="mb-4">
              <label className="text-white text-shadow-sm">Tell us a little bit about what you'd like</label>
              <div>
                <textarea
                  name="message"
                  rows="6"
                  className="text-black shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder=""
                  onChange={this.handleInputChange}
                ></textarea>
              </div>
            </div>

            <div className="mb-4">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-shadow font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-400 w-full"
              >
                { this.state.isSubmitting
                  ? <Spinner />
                  : 'Send'
                }
              </button>
            </div>

            <div className="text-lg text-purple-200 italic">
              <p>Inquiries shall be responded to within 24 hours.</p>
            </div>
        </form>
      </div>
    )
  }
}

export default Order;