import { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div
        className="flex-1 flex flex-col items-center justify-center"
        style={{
        }}
      >
        <div>
          <p className="mb-4">Chef Thuy-Linh Carroll rescued a six-month old Boston Terrier, named “Buster.” </p>
          <p className="mb-4">Caring for Buster taught her so much about living intentionally and taking time to enjoy life. By 2020, she had earned degrees in both Chemistry and Bakery/Pastry Arts. She used her knowledge and experience to launch a business from home, creating bespoke cakes, intended to celebrate life’s milestones. </p>
          <p className="mb-4">Since opening shop in Dallas-Fort Worth, Chef Thuy has appeared in D Magazine, VoyageDallas Magazine, and, most recently, on the televised show “Crime Scene Kitchen.”</p>
          <p className="mb-4">Her passionate work ethic and strong sense of generosity have earned the praise of many throughout the DFW metroplex, and the far-reaches of Texas.</p>
          <p className="">But, behind every friendly neighborhood cake designer, there’s an even friendlier Boston Terrier pulling the strings. Er, leash.</p>
        </div>
      </div>
    )
  }
}

export default About;