import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import './index.css';
import Home from './Pages/Home';

let search = window.location.search;
let params = new URLSearchParams(search);
let card = params.get('card');

if (card !== undefined && card === 'g8z142jp59') {
  window.location.href = process.env.PUBLIC_URL + 'bustersbakeshop.vcf';
}

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>,
  document.getElementById('root')
);
