import { Component } from 'react';
import ReactGA from 'react-ga';

import InteractiveContent from '../Components/InteractiveContent';
import Footer from '../Components/Footer';
import History from '../Components/History';
import NavLink from '../Components/NavLink';

import About from './Home/About';
import Merchandise from './Home/Merchandise';
import Order from './Home/Order';
import Portfolio from './Home/Portfolio';
import Reviews from './Home/Reviews';

class Home extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      historyUnlisten: null,
    }
  }

  componentDidMount(e)
  {
    ReactGA.initialize('G-0KH4NK14PV');
    if (History.location.hash.length > 0) {
      ReactGA.pageview(History.location.hash.substring(1));
    } else {
      ReactGA.pageview('home');
    }
    var unlisten = History.listen(({ action, location }) => {
      if (location.hash.length > 0) {
        ReactGA.pageview(location.hash.substring(1));
      } else {
        ReactGA.pageview('home');
      }
    });
    this.setState({ historyUnlisten: unlisten });
  }

  componentWillUnmount()
  {
    this.state.historyUnlisten();
  }

  render()
  {
    return (
      <div className="flex flex-col h-screen">
        <div id="header" className="bg-black">
          <div className="w-full px-4 lg:px-8">
            <div className="flex flex-col lg:flex-row items-center lg:justify-between">
              <div className="flex flex-1 lg:flex-none items-center">
                <div className="flex-shrink-0">
                  <a href="/" className="brand">
                    <img src="/img/logo.png" alt="Buster's Bakeshop" className="object-contain w-48 lg:w-64 h-full p-4" />
                  </a>
                </div>
              </div>

              <div className="flex-1 lg:flex-none">
                <nav className="text-xs lg:text-lg ml-2 lg:ml-10 flex items-baseline lg:space-x-4 justify-center">
                  <NavLink to="#reviews" activeColor="text-blue-500">Reviews</NavLink>
                  <NavLink to="#about" activeColor="text-red-700">About</NavLink>
                  <NavLink to="#order" activeColor="text-purple-600">Order</NavLink>
                  <NavLink to="#portfolio" activeColor="text-pink-600">Portfolio</NavLink>
                  <NavLink to="#merchandise" activeColor="text-yellow-600">Merchandise</NavLink>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div
            id="top"
            className="flex flex-col justify-center h-full bg-grey-500 p-12"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + 'img/content/home/celebrate.jpg'})`,
              backgroundPosition: '50% 50%',
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed'
            }}
          >
            <div className="text-white text-6xl mb-5 lg:mb-10 lg:text-9xl text-shadow-xl">Celebrate<br/>YOU!</div>
            <div className="text-white text-lg mb-20 lg:mb-40">Bespoke birthday cakes, macarons, and cookies for all of your celebrations.</div>
            <div>
            <NavLink to="#order" className="bg-green-500 hover:ring-3 p-3 rounded-3xl">
              Treat Yourself
            </NavLink>
            </div>
          </div>

          <InteractiveContent
            name="reviews"
            background="img/content/home/reviews.jpg"
            color="text-white"
            heading="Memorable Moments"
            subheading="&quot;Chef Thuy is simply amazing. She made my 13-year-old son's shark cake...&quot;"
            showMore="Read Full Reviews"
            showMoreColor="bg-blue-500"
          >
            <Reviews/>
          </InteractiveContent>

          <InteractiveContent
            name="about"
            background="img/content/home/buster.jpg"
            color="text-white"
            heading="Why is a Boston Terrier here?"
            subheading="Finding herself caught up in a corporate rat race and wanting a companion..."
            showMore="Read Our Story"
            showMoreColor="bg-red-700"
          >
            <About/>
          </InteractiveContent>

          <InteractiveContent
            name="order"
            background="img/content/home/order.jpg"
            color="text-white"
            heading="Do our cakes taste as good as they look?"
            subheading="There's only one way to find out..."
            showMore="Start Here"
            showMoreColor="bg-purple-600"
          >
            <Order/>
          </InteractiveContent>

          <InteractiveContent
            name="portfolio"
            background="img/content/home/portfolio.jpg"
            color="text-white"
            heading="Cake Studies"
            subheading="Interested in our design process?"
            showMore="Take a Look"
            showMoreColor="bg-pink-600"
          >
            <Portfolio/>
          </InteractiveContent>

          <InteractiveContent
            name="merchandise"
            background="img/content/home/merchandise.jpg"
            bgSize="bg-75 lg:auto"
            color="text-white"
            heading="Merchandise"
            subheading="Check out the Buster's Bakeshop store!"
            expandInitial="true"
            showMoreColor="bg-yellow-600"
          >
            <Merchandise/>
          </InteractiveContent>

          <Footer />
        </div>
      </div>
    )
  }
}

export default Home;