import { Component } from 'react';

class Portfolio extends Component {
  render() {
    return (
      <div
        style={{
        }}
      >
        <p className="mb-4">These are firsthand accounts of our best, Buster-approved works of art. From design to delivery, the process is laid out here in every delicious detail.</p>

        {/* <div className="grid grid-cols-3 grid-rows-3 gap-8">
          <div className="bg-white box-shadow h-44 w-44"></div>
          <div className="bg-white box-shadow h-44 w-44"></div>
          <div className="bg-white box-shadow h-44 w-44"></div>

          <div className="bg-white box-shadow h-44 w-44"></div>
          <div className="bg-white box-shadow h-44 w-44"></div>
          <div className="bg-white box-shadow h-44 w-44"></div>

          <div className="bg-white box-shadow h-44 w-44"></div>
          <div className="bg-white box-shadow h-44 w-44"></div>
          <div className="bg-white box-shadow h-44 w-44"></div>
        </div> */}

        <div className="flex flex-col items-center">
          <img
            alt="Schematics"
            src="/img/content/portfolio/one.jpg"
            className="object-contain w-48 lg:w-96 h-full p-4"
          />
          <img
            alt="Finished Product"
            src="/img/content/portfolio/two.jpg"
            className="object-contain w-48 lg:w-96 h-full p-4"
          />
        </div>
      </div>
    )
  }
}

export default Portfolio;