import { Component } from 'react';
import ReactGA from 'react-ga';

class InteractiveContent extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      expanded: this.props.expandInitial || false
    };
  }

  callToAction(e)
  {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
    ReactGA.modalview(this.props.name);
  }

  render()
  {
    const containerClasses = [
      "flex",
      "flex-cols",
      "min-h-full",
      "w-full",
      "overflow-x-hidden",
      "bg-black",
      "bg-fixed",
      "bg-no-repeat",
      "bg-bottom",
      "lg:bg-center",
      (this.props.bgSize || "bg-80 lg:bg-auto")
    ].join(" ");

    const showMoreClasses = [
      "text-sm",
      "lg:text-medium",
      "p-2",
      "lg:p-3",
      "rounded-3xl",
      (this.props.showMoreColor || "bg-red-600")
    ].join(" ");

    let showLess;
    let showMore;
    let rightPaneClasses;

    if (this.state.expanded) {
      showLess = (
        <div className="cta hidden">
          <button onClick={this.callToAction.bind(this)}>
            {this.props.showLess || 'Show Less'}
          </button>
        </div>
      );
      showMore = '';
      rightPaneClasses = 'flex-1 hidden lg:block';
    } else {
      showLess = '';
      showMore = (
        <div className="cta ">
          <button onClick={this.callToAction.bind(this)} className={showMoreClasses}>
            {this.props.showMore || 'Show More'}
          </button>
        </div>
      )
      rightPaneClasses = 'flex-1';
    }

    return (
      <div
        id={this.props.name}
        className={containerClasses}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + this.props.background})`,
        }}
      >
        <div className="flex-1 flex items-center justify-center bg-black bg-opacity-75 p-12">
          <div className="flex-1 flex flex-col">
            <div>
              <div className={`text-white text-2xl lg:text-6xl font-bold mb-4 ${this.props.color}`}>{this.props.heading}</div>
              <div className="text-gray-400 text-lg lg:text-xl mb-4">{this.props.subheading}</div>
              {showMore}
            </div>
            <div className={'' + (this.state.expanded ? 'interactive_shown' : 'interactive_hidden')}>
              {this.props.children}
              {showLess}
            </div>
          </div>
        </div>
        <div className={rightPaneClasses}></div>
      </div>
    )
  }
}

export default InteractiveContent;