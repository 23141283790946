import { Component } from 'react';

class Reviews extends Component {
  render() {
    return (
      <div
        className="flex-1 flex flex-col items-center justify-center"
        style={{
        }}
      >
        <div>

          <p className="mb-4">“The owner of Buster’s Bakeshop worked with me, while living in Germany, to create a wonderful cake for my sister’s birthday who lives in Dallas. Not only did she deliver it in person to my sister on New Year’s Day, she provided a beautiful and delicious cake! We would definitely hire her again!” – Christina S.</p>

          <p className="mb-4">“Gorgeous, creative, delicious. What more could you ask for? Thuy-Linh will make anything you want. She loves a challenge and everything she does is done with a meticulous attention to detail and beauty. So often with specialty bakes, you'll get the artistry, but the taste will be sub par. Not so here! Thuy-Linh pays just as much attention to how delicious everything is. Everything she's ever made that I've tasted had been perfectly scrumptious. You get the quality you pay for.” - Lindsay S.</p>

          <p className="">“We found our forever bakery chef, Chef Thuy. We were referred to her. [...] The whole experience from beginning to end was amazing. She’s personable, inquisitive, professional, meticulous, talented, artistic and overall good people. I’d keep ordering from her just because of her customer service. She talked and treated me like family. [...] This cake was not a normal sponge cake. Its a huge 8 layer macaron cake. [...] I could go on and on as the cake and service was over and beyond expectations. Did I mention she delivers?!? Huge plus! [...] Thank you Chef for making my daughter’s day memorable.” - Rosalynn K.</p>

        </div>
      </div>
    )
  }
}

export default Reviews;