import { Component } from 'react';

class Merchandise extends Component
{
  render()
  {
    return (
      <a
        href="https://bustersbakeshop.square.site"
        target="_blank"
        rel="noreferrer"
        className="justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-shadow font-medium rounded-md text-white bg-yellow-700 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 w-full"
      >
        Go to Store
      </a>
    )
  }
}

export default Merchandise;